<template>
  <div>
    <div class="block-action">
      <div
        v-if="checkPermission('GCMMCM')"
        class="block-icon-reglement-fac mr-1"
        title="Modifier configuration"
        @click.prevent.stop="handleClickAction('update')"
      >
        <font-awesome-icon icon="pencil-alt" />
      </div>
      <div
        v-if="checkPermission('GCMDCM')"
        class="block-icon-reglement-fac mr-1"
        title="Dupliquer configuration"
        @click.prevent.stop="handleClickAction('duplicate')"
      >
        <font-awesome-icon icon="copy" />
      </div>
      <div
        v-if="checkPermission('GCMSCM')"
        class="block-icon-reglement-fac mr-1"
        title="Supprimer configuration"
        @click.prevent.stop="handleModalConfig('delete', 'show')"
      >
        <font-awesome-icon icon="trash" />
      </div>
    </div>
    <!--MODAL DELETE CONFIGURATION-->
    <v-dialog
      v-model="deleteModalConfig"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer une configuration </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalConfig('delete', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div class="mt-4 ml-3 text-center">
            <p class="dark-class">
              Êtes-vous sur de vouloir supprimer cette configuration
              <strong>
                {{ item ? item.name : '-' }}
              </strong>
            </p>
          </div>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleDeleteConfComptaRh"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalConfig('delete', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL UPDATE CONFIGURATION-->
    <v-dialog
      v-model="updateModalConfig"
      persistent
      max-width="1500px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="v-dialog.custom-vuetify-dialog-list"
    >
      <v-card v-if="configurationToUpdate && configurationToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier une configuration </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalConfig('update', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="4">
                <v-select
                  placeholder="Séléctionnez"
                  :items="ListType"
                  class="select-menu"
                  disabled
                  label="Type *"
                  :persistent-placeholder="true"
                  v-model="configurationToUpdate.type"
                  @change="handleErrorConfig"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="configurationToUpdate.service_bien"
                  :items="computedListConfig"
                  @change="handleErrorConfig"
                  placeholder="Séléctionnez"
                  disabled
                  :persistent-placeholder="true"
                  label="Type de configuration *"
                  item-text="text"
                  item-value="value"
                  :auto-focus="false"
                  no-data-text="Aucune configuration trouvée"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedListJourCreation"
                  class="select-menu"
                  label="Jour de création"
                  :persistent-placeholder="true"
                  v-model="configurationToUpdate.jour_creation"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="configurationToUpdate.vendeur_id"
                  :items="
                    getSettingFilialesTh.filter(
                      item => item.type != 'particulier'
                    )
                  "
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  @change="handleErrorConfig"
                  disabled
                  label="Société *"
                  item-text="name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="configurationToUpdate.acheteur_id"
                  :items="
                    getSettingFilialesTh.filter(
                      item => item.type != 'particulier'
                    )
                  "
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  @change="handleErrorConfig"
                  disabled
                  label="Client *"
                  item-text="name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucun client trouvée"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Montant HT"
                  :persistent-placeholder="true"
                  v-model="configurationToUpdate.montant"
                  @input="calculTTC"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-row>
                  <v-col cols="9">
                    <v-select
                      placeholder="Séléctionnez"
                      :items="getAllTvas"
                      class="select-menu"
                      label="TVA %"
                      :persistent-placeholder="true"
                      v-model="configurationToUpdate.tva"
                      @input="calculTTC"
                      item-text="valeur"
                      item-value="valeur"
                      outlined
                      dense
                      :auto-focus="false"
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="#704ad1"
                      item-color="#704ad1"
                    >
                    </v-select
                  ></v-col>
                  <v-col cols="3">
                    <div
                      class="btn-add-tva"
                      small
                      @click.stop.prevent="ModalAddTva('show', 'update')"
                    >
                      <font-awesome-icon icon="plus" />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Montant TTC"
                  :persistent-placeholder="true"
                  v-model="montant_ttc"
                  :disabled="true"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-menu :close-on-content-click="true" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="configurationToUpdate.date_debut"
                      label="Date début *"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      required
                      clearable
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      :autofocus="false"
                      color="#704ad1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="704ad1"
                    v-model="configurationToUpdate.date_debut"
                    @change="handleErrorConfig"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu :close-on-content-click="true" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="configurationToUpdate.date_fin"
                      label="Date fin *"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      required
                      clearable
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      :autofocus="false"
                      color="#704ad1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="704ad1"
                    v-model="configurationToUpdate.date_fin"
                    @change="handleErrorConfig"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-select
                  placeholder="Séléctionnez"
                  :items="freqOptions"
                  class="select-menu"
                  label="Fréquence"
                  :persistent-placeholder="true"
                  v-model="configurationToUpdate.frequence"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Objet"
                  v-model="configurationToUpdate.objet"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="configurationToUpdate.description"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
          </form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleUpdateConfigComptaRh"
            :loading="loading"
            :disabled="validateToAddconf"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalConfig('update', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL DUPLICATE CONFIGURATION-->
    <v-dialog
      v-model="duplicateModalConfig"
      persistent
      max-width="1500px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="v-dialog.custom-vuetify-dialog-list"
    >
      <v-card v-if="configurationToUpdate && configurationToUpdate != null">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Dupliquer une configuration </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleModalConfig('duplicate', 'hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <form class="form-add">
            <v-row>
              <v-col cols="4">
                <v-select
                  placeholder="Séléctionnez"
                  :items="ListType"
                  class="select-menu"
                  label="Type *"
                  :persistent-placeholder="true"
                  v-model="configurationToUpdate.type"
                  @change="handleErrorConfig"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="configurationToUpdate.service_bien"
                  :items="computedListConfig"
                  @change="handleErrorConfig"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Type de configuration *"
                  item-text="text"
                  item-value="value"
                  :auto-focus="false"
                  no-data-text="Aucune configuration trouvée"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-select
                  placeholder="Séléctionnez"
                  :items="computedListJourCreation"
                  class="select-menu"
                  label="Jour de création"
                  :persistent-placeholder="true"
                  v-model="configurationToUpdate.jour_creation"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="configurationToUpdate.vendeur_id"
                  :items="
                    getSettingFilialesTh.filter(
                      item => item.type != 'particulier'
                    )
                  "
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  @change="handleErrorConfig"
                  label="Société *"
                  item-text="name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="configurationToUpdate.acheteur_id"
                  :items="
                    getSettingFilialesTh.filter(
                      item => item.type != 'particulier'
                    )
                  "
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  @change="handleErrorConfig"
                  label="Client *"
                  item-text="name"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucun client trouvée"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Montant HT"
                  :persistent-placeholder="true"
                  v-model="configurationToUpdate.montant"
                  @input="calculTTC"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-row>
                  <v-col cols="9">
                    <v-select
                      placeholder="Séléctionnez"
                      :items="getAllTvas"
                      class="select-menu"
                      label="TVA %"
                      :persistent-placeholder="true"
                      v-model="configurationToUpdate.tva"
                      @input="calculTTC"
                      item-text="valeur"
                      item-value="valeur"
                      outlined
                      dense
                      :auto-focus="false"
                      :menu-props="{ bottom: true, offsetY: true }"
                      color="#704ad1"
                      item-color="#704ad1"
                    >
                    </v-select
                  ></v-col>
                  <v-col cols="3">
                    <div
                      class="btn-add-tva"
                      small
                      @click.stop.prevent="ModalAddTva('show', 'duplicate')"
                    >
                      <font-awesome-icon icon="plus" />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2">
                <v-text-field
                  label="Montant TTC"
                  :persistent-placeholder="true"
                  v-model="montant_ttc"
                  :disabled="true"
                  outlined
                  type="number"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-menu :close-on-content-click="true" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="configurationToUpdate.date_debut"
                      label="Date début *"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      required
                      clearable
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      :autofocus="false"
                      color="#704ad1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="704ad1"
                    v-model="configurationToUpdate.date_debut"
                    @change="handleErrorConfig"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-menu :close-on-content-click="true" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="configurationToUpdate.date_fin"
                      label="Date fin *s"
                      outlined
                      readonly
                      hide-details
                      v-on="on"
                      required
                      clearable
                      :persistent-placeholder="true"
                      placeholder="Sélectionner un date"
                      prepend-inner-icon="mdi-calendar"
                      :autofocus="false"
                      color="#704ad1"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    color="#704ad1"
                    header-color="#704ad1"
                    event-color="704ad1"
                    v-model="configurationToUpdate.date_fin"
                    @change="handleErrorConfig"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <v-select
                  placeholder="Séléctionnez"
                  :items="freqOptions"
                  class="select-menu"
                  label="Fréquence"
                  :persistent-placeholder="true"
                  v-model="configurationToUpdate.frequence"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                  :auto-focus="false"
                  :menu-props="{ bottom: true, offsetY: true }"
                  color="#704ad1"
                  item-color="#704ad1"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Objet"
                  v-model="configurationToUpdate.objet"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  outlined
                  label="Description"
                  v-model="configurationToUpdate.description"
                  :persistent-placeholder="true"
                  rows="3"
                  required
                  color="#704ad1"
                ></v-textarea>
              </v-col>
            </v-row>
          </form>
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            type="submit"
            @click.prevent="handleDuplicateConfigComptaRh"
            :loading="loading"
            :disabled="validateToAddconf"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handleModalConfig('duplicate', 'hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL TVA-->
    <v-dialog
      v-model="modalAddTva"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter TVA </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="ModalAddTva('hide', actionTva)"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <v-form class="form-add" ref="formAdd">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Tva *"
                  v-model="valeur"
                  :persistent-placeholder="true"
                  required
                  :rules="[v => !!v || 'Tva est obligatoire']"
                  class="msg-error"
                  validate-on-blur
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col clos="6">
                <v-text-field
                  label="Compte tva vente"
                  v-model="compte_vente"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
              <v-col clos="6">
                <v-text-field
                  label="Compte tva achat"
                  v-model="compte_achat"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col clos="6">
                <v-text-field
                  label="compte marchandise(HT)  "
                  v-model="compte_marchandise"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
              <v-col clos="6">
                <v-text-field
                  label="Compte prestation(HT) "
                  v-model="compte_prestation"
                  :persistent-placeholder="true"
                  required
                  outlined
                  color="#704ad1"
                  class="msg-error"
                  validate-on-blur
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row class="margin-none">
              <v-col cols="2">
                <v-checkbox
                  label="Activer"
                  class="checked-tva margin-none"
                  color="#704ad1"
                  :value="true"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                  v-model="is_active"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <div v-if="errorTvaUnite" class="message-error-modal">
          <ul v-if="Array.isArray(errorTvaUnite)" class="mb-0">
            <li v-for="(e, index) in errorTvaUnite" :key="index">
              {{ e }}
            </li>
          </ul>
          <div class="message-error-modal" v-else>{{ errorTvaUnite }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="addTav('tva')"
            :loading="loadingTvaUnite"
            :disabled="loadingTvaUnite"
            :class="{ loader: loadingTvaUnite }"
          >
            Ajouter
          </v-btn>
          <v-btn text @click="ModalAddTva('hide', actionTva)">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'action-configuration',
  data() {
    return {
      deleteModalConfig: false,
      updateModalConfig: false,
      duplicateModalConfig: false,
      validateToAddconf: false,
      configurationToUpdate: null,
      oldConfigurationToUpdate: null,
      actionTva: null,
      valeur: 0,
      tvaToAdd: null,
      modalAddTva: false,
      errorTvaUnite: null,
      loadingTvaUnite: false,
      freqOptions: [{ text: 'Mensuel', value: 'mensuel' }],
      montant_ttc: 0,
      loading: false,
      error: null,
      compte_vente: null,
      compte_achat: null,
      is_active: true,
      compte_marchandise: null,
      compte_prestation: null
    };
  },
  props: {
    item: { required: true },
    type: { required: true }
  },
  methods: {
    ...mapActions([
      'deleteConfComptaRh',
      'addNewConfigurationMensuel',
      'updateConfigurationComptaRh',
      'checkPeriodConfig',
      'addUniteTva'
    ]),
    async addTav(type) {
      if (this.$refs.formAdd.validate()) {
        this.errorTvaUnite = '';
        if (this.valeur > 100) {
          this.errorTvaUnite = 'tva doit etre inferieur à 100';
        } else if (this.valeur === '' || this.valeur === null) {
          this.errorTvaUnite = 'ce champ et obligatoire';
        } else {
          this.loadingTvaUnite = true;
          const response = await this.addUniteTva({
            valeur: this.valeur,
            type: type,
            compte_vente: this.compte_vente,
            compte_achat: this.compte_achat,
            compte_marchandise: this.compte_marchandise,
            compte_prestation: this.compte_prestation,
            is_active: this.is_active
          });
          if (response.success) {
            this.ModalAddTva('hide');
            if (this.actionTva == 'update') {
              this.updateModalConfig = true;
            } else {
              this.duplicateModalConfig = true;
            }
            this.valeur = 0;
            this.loadingTvaUnite = false;
          } else {
            this.loadingTvaUnite = false;
            this.errorTvaUnite = response.response;
          }
        }
      }
    },
    ModalAddTva(action, method) {
      this.actionTva = method;
      if (action === 'show') {
        if (method == 'update') {
          this.updateModalConfig = false;
        } else {
          this.duplicateModalConfig = false;
        }
        this.modalAddTva = true;
      } else if (action === 'hide') {
        if (method == 'update') {
          this.updateModalConfig = true;
        } else {
          this.duplicateModalConfig = true;
        }
        this.$refs.formAdd.reset();
        this.modalAddTva = false;
        this.resteTvaUniteModal();
      }
    },
    resteTvaUniteModal() {
      this.valeur = null;
      this.errorTvaUnite = null;
      this.compte_vente = null;
      this.compte_achat = null;
      this.compte_marchandise = null;
      this.compte_prestation = null;
      this.is_active = true;
    },
    async handleDuplicateConfigComptaRh() {
      this.loading = true;
      const response = await this.addNewConfigurationMensuel({
        configurationToAdd: this.configurationToUpdate,
        type: this.type
      });
      if (response.success) {
        this.loading = false;
        this.handleModalConfig('duplicate', 'hide');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    handleClickAction(method) {
      this.configurationToUpdate = { ...this.item };
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToUpdate.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
      this.oldConfigurationToUpdate = this.item;
      if (method == 'update') {
        this.handleModalConfig('update', 'show');
      } else {
        this.handleModalConfig('duplicate', 'show');
      }
      this.handleErrorConfig();
    },
    async handleUpdateConfigComptaRh() {
      this.loading = true;
      const response = await this.updateConfigurationComptaRh({
        configurationToUpdate: this.configurationToUpdate,
        oldConfigurationToUpdate: this.oldConfigurationToUpdate
      });
      if (response.success) {
        this.loading = false;
        this.handleModalConfig('update', 'hide');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    calculTTC() {
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToUpdate.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
    },
    async handleErrorConfig() {
      var objToSend = {
        vendeur_id: this.configurationToUpdate.vendeur_id
          ? this.configurationToUpdate.vendeur_id
          : null,
        acheteur_id: this.configurationToUpdate.acheteur_id
          ? this.configurationToUpdate.acheteur_id
          : null,
        date_debut: this.configurationToUpdate.date_debut
          ? this.configurationToUpdate.date_debut
          : null,
        date_fin: this.configurationToUpdate.date_fin
          ? this.configurationToUpdate.date_fin
          : null,
        type: this.configurationToUpdate.type,
        service_bien: this.configurationToUpdate.service_bien
      };
      if (objToSend.vendeur_id != null && objToSend.acheteur_id != null) {
        const response = await this.checkPeriodConfig(objToSend);
        this.validateToAddconf = true;
        if (response?.success) {
          if (response.response) {
            this.validateToAddconf = false;
            this.error = null;
          }
        } else {
          this.error = response.error;
          this.validateToAddconf = true;
        }
      }
    },
    handleModalConfig(method, action) {
      if (method == 'delete') {
        if (action == 'show') {
          this.deleteModalConfig = true;
        } else {
          this.deleteModalConfig = false;
          this.resetModal();
        }
      }
      if (method == 'update') {
        if (action == 'show') {
          this.updateModalConfig = true;
        } else {
          this.updateModalConfig = false;
          this.resetModal();
        }
      }
      if (method == 'duplicate') {
        if (action == 'show') {
          this.duplicateModalConfig = true;
        } else {
          this.duplicateModalConfig = false;
          this.resetModal();
        }
      }
    },
    resetModal() {
      this.loading = false;
      this.error = null;
      this.validateToAddconf = false;
    },
    async handleDeleteConfComptaRh() {
      this.loading = true;
      const response = await this.deleteConfComptaRh({
        configToDelete: this.item,
        type: this.type
      });
      if (response.success) {
        this.loading = false;
        this.handleModalConfig('delete', 'hide');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'biens',
      'services',
      'getSettingFilialesTh',
      'getAllTvas'
    ]),
    computedListJourCreation() {
      return [
        { text: 'Jour du création', value: null },
        { text: 'Fin du mois', value: -1 },
        { text: '01', value: 1 },
        { text: '02', value: 2 },
        { text: '03', value: 3 },
        { text: '04', value: 4 },
        { text: '05', value: 5 },
        { text: '06', value: 6 },
        { text: '07', value: 7 },
        { text: '08', value: 8 },
        { text: '09', value: 9 },
        { text: '10', value: 10 },
        { text: '11', value: 11 },
        { text: '12', value: 12 },
        { text: '13', value: 13 },
        { text: '14', value: 14 },
        { text: '15', value: 15 },
        { text: '16', value: 16 },
        { text: '17', value: 17 },
        { text: '18', value: 18 },
        { text: '19', value: 19 },
        { text: '21', value: 21 },
        { text: '22', value: 22 },
        { text: '23', value: 23 },
        { text: '24', value: 24 },
        { text: '25', value: 25 },
        { text: '26', value: 26 },
        { text: '27', value: 27 },
        { text: '28', value: 28 },
        { text: '29', value: 29 },
        { text: '30', value: 30 },
        { text: '31', value: 31 }
      ];
    },
    ListType() {
      let list = [];
      if (this.checkPermission('GBHT')) {
        list.push({ value: 'bien', text: 'Bien' });
      }
      if (this.checkPermission('GCM')) {
        list.push({ value: 'service', text: 'Service' });
      }
      return list;
    },
    computedListConfig() {
      let selected = [];
      if (this.item?.type == 'service') {
        return this.services?.map(service => {
          return {
            value: service.id,
            text: service.nom
          };
        });
      } else if (this.item?.type == 'bien') {
        return this.biens
          ?.filter(item => item.blocked == false)
          .map(bien => {
            return {
              value: bien.id,
              text: bien.nom
            };
          });
      } else {
        return selected;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.block-action {
  display: flex;
  justify-content: center;
  // margin-left: 26%;
}
</style>
